import "../styles/components/spac-teaser.scss";

import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";

interface SpacTeaserProps {
  data: {
    image: {
      altText: string;
      sourceUrl: string;
    };
    logo: {
      altText: string;
      sourceUrl: string;
    };
    link: string;
    videoMobile: {
      mediaItemUrl: string;
    };
    videoDesktop: {
      mediaItemUrl: string;
    };
  };
}

/**
 * @param props
 * @param props.data
 */
function SpacTeaser({ data }: SpacTeaserProps) {
  const videoRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (isHovering && videoRef?.current) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isHovering]);

  return (
    <div
      className={classNames("SpacTeaser", { "SpacTeaser--hover": isHovering })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {data?.logo && (
        <img
          alt={data?.logo?.altText}
          className="SpacTeaser-logo"
          src={data?.logo?.sourceUrl}
        />
      )}
      {data?.image && (
        <img
          alt={data?.image?.altText}
          className="SpacTeaser-background"
          src={data?.image?.sourceUrl}
        />
      )}
      <video
        className="SpacTeaser-backgroundVideo"
        loop
        muted
        playsInline
        preview={data?.image?.sourceUrl}
        ref={videoRef}
      >
        <source src={data?.videoDesktop?.mediaItemUrl} type="video/mp4" />
      </video>
      <div className="SpacTeaser-clickShield"></div>
      <a href={data?.link} rel="noreferrer" target="_blank"></a>
    </div>
  );
}

export default SpacTeaser;
