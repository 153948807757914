import "../styles/components/spac-feed.scss";

import React from "react";
import SpacTeaser from "./SpacTeaser";

interface SpacFeed {
  items: {
    image: {
      altText: string;
      sourceUrl: string;
    }
    logo: {
      altText: string;
      sourceUrl: string;
    }
    link: string;
    videoMobile: {
      mediaItemUrl: string;
    }
    videoDesktop: {
      mediaItemUrl: string;
    }
  }[]
}

/**
 * @param props
 * @param props.items
 */
function SpacFeed({ items = [] }: SpacFeed) {
  return (
    <div className="SpacFeed">
      {items.map((item, i) => (
        <div className="SpacFeed-item" key={i}>
          <SpacTeaser data={item} />
        </div>
      ))}
    </div>
  );
}

export default SpacFeed;
