import "../../styles/pages/spacs.scss";

import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/Layout";
import React from "react";
import SEO from "../../components/SEO";
import SpacFeed from "../../components/SpacFeed";

/**
 *
 */
function SPACs({ pageContext }) {
  const { seo } = pageContext;
  const { title, metaDesc } = seo;
  const spacsData = pageContext;

  const bodyData = spacsData?.pageTemplateSPACs;
  const items = spacsData?.pageTemplateSPACs?.spacsWork || [];

  return (
    <Layout gradient={true} gradientMobileOnly={true} theme="dark">
      <SEO title={title} description={metaDesc} />

      <article className="SPACs">
        <div className="SPACs-main">
          {bodyData?.heading && (
            <h1 className="SPACs-mainTitle">{bodyData?.heading}</h1>
          )}
          <p className="SPACs-mainContent">{bodyData?.body}</p>
        </div>
        <div className="SPACs-work">
          <SpacFeed items={items} />
        </div>
      </article>
    </Layout>
  );
}

export default SPACs;
// const spacsData = useStaticQuery(graphql`
// query {
//   wpPage(slug: { eq: "spacs" }) {
//     pageTemplateSPACs {
//       heading
//       body
//       spacsWork {
//         image {
//           altText
//           sourceUrl
//         }
//         logo {
//           altText
//           sourceUrl
//         }
//         link
//         videoMobile {
//           mediaItemUrl
//         }
//         videoDesktop {
//           mediaItemUrl
//         }
//       }
//     }
//   }
// }
// `);
